import styled, { useTheme } from 'styled-components';
import { ReactComponent as MetaMaskFox } from '../assets/metamask_fox.svg';
import { MetaMask } from './MetaMask';
import { PoweredBy } from './PoweredBy';
import { SnapLogo } from './SnapLogo';
import Discord from '../assets/discord.png';
import Twitter from '../assets/twitter.png';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 2.4rem;
  border-top: 1px solid ${(props) => props.theme.colors.border.default};
`;

const PoweredByButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  border-radius: ${({ theme }) => theme.radii.button};
  box-shadow: ${({ theme }) => theme.shadows.button};
  background-color: ${({ theme }) => theme.colors.background.alternative};
`;

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Footer = () => {
  const theme = useTheme();

  return (
    <FooterWrapper>
      <PoweredByButton href="https://docs.metamask.io/" target="_blank">
        <MetaMaskFox />
        <PoweredByContainer>
          <PoweredBy color={theme.colors.text.muted} />
          <MetaMask color={theme.colors.text.default} />
        </PoweredByContainer>
      </PoweredByButton>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <p>Build By ❤️ Team Riff</p>
        <div style={{ display: 'contents' }}>
          <a href="https://discord.gg/UvbYH7mCf5" target="_blank">
            {' '}
            <img src={Discord} alt="" style={{ width: '2.5rem' }} />
          </a>
          <a href="https://twitter.com/_getriff" target="_blank">
            {' '}
            <img src={Twitter} alt="" style={{ width: '2.5rem' }} />
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
};
