export const SnapLogo = ({ color, size }: { color: string; size: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 206 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_209_4)">
      <path
        d="M18.5739 120.498C9.02391 129.368 3.5718 142.799 4.19689 147.867C4.19689 147.867 -0.178824 166.721 32.326 177.668C45.1201 181.977 89.626 198.347 111.088 208.078C165.471 238.488 187.974 227.54 196.725 208.078C215.228 107.361 169.846 36.3648 144.843 13.4562C131.299 0.481426 93.5849 -14.5203 81.0832 28.661C68.0144 73.8006 62.3303 79.7493 48.5783 93.1295C34.8263 106.51 32.326 107.726 18.5739 120.498Z"
        fill="#75FBFB"
      />
      <path
        d="M18.5739 120.498C9.02391 129.368 3.5718 142.799 4.19689 147.867C4.19689 147.867 -0.178824 166.721 32.326 177.668C45.1201 181.977 89.626 198.347 111.088 208.078C165.471 238.488 187.974 227.54 196.725 208.078C215.228 107.361 169.846 36.3648 144.843 13.4562C131.299 0.481426 93.5849 -14.5203 81.0832 28.661C68.0144 73.8006 62.3303 79.7493 48.5783 93.1295C34.8263 106.51 32.326 107.726 18.5739 120.498Z"
        fill="url(#paint0_linear_209_4)"
        fill-opacity="0.9"
      />
      <path
        d="M18.5739 120.498C9.02391 129.368 3.5718 142.799 4.19689 147.867C4.19689 147.867 -0.178824 166.721 32.326 177.668C45.1201 181.977 89.626 198.347 111.088 208.078C165.471 238.488 187.974 227.54 196.725 208.078C215.228 107.361 169.846 36.3648 144.843 13.4562C131.299 0.481426 93.5849 -14.5203 81.0832 28.661C68.0144 73.8006 62.3303 79.7493 48.5783 93.1295C34.8263 106.51 32.326 107.726 18.5739 120.498Z"
        fill="url(#paint1_linear_209_4)"
        fill-opacity="0.7"
      />
      <path
        d="M18.5739 120.498C9.02391 129.368 3.5718 142.799 4.19689 147.867C4.19689 147.867 -0.178824 166.721 32.326 177.668C45.1201 181.977 89.626 198.347 111.088 208.078C165.471 238.488 187.974 227.54 196.725 208.078C215.228 107.361 169.846 36.3648 144.843 13.4562C131.299 0.481426 93.5849 -14.5203 81.0832 28.661C68.0144 73.8006 62.3303 79.7493 48.5783 93.1295C34.8263 106.51 32.326 107.726 18.5739 120.498Z"
        fill="url(#paint2_radial_209_4)"
        fill-opacity="0.6"
      />
    </g>
    <circle cx="88.5584" cy="122.012" r="8.51248" fill="white" />
    <circle cx="117.501" cy="122.012" r="8.51248" fill="white" />
    <circle cx="146.443" cy="122.012" r="8.51248" fill="white" />
    <defs>
      <filter
        id="filter0_d_209_4"
        x="0"
        y="0"
        width="205.046"
        height="235"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.075375 0 0 0 0 0.0675 0 0 0 0 0.225 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_209_4"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_209_4"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_209_4"
        x1="67.7594"
        y1="102.176"
        x2="134.732"
        y2="130.22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#75FBFD" />
        <stop offset="1" stop-color="#4C57D1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_209_4"
        x1="171.525"
        y1="204.353"
        x2="224.185"
        y2="71.3323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#440DE8" />
        <stop offset="1" stop-color="#71F3FD" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_209_4"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(247.786 183.674) rotate(-130.333) scale(183.503 152.223)"
      >
        <stop offset="0.143371" stop-color="#540DDF" />
        <stop offset="0.524494" stop-color="#9A64BF" />
        <stop offset="0.701573" stop-color="#CC51BE" stop-opacity="0.42" />
        <stop offset="1" stop-color="#71F3FD" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
