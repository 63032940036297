import { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { MetamaskActions, MetaMaskContext } from '../hooks';
import { connectSnap, getThemePreference, getSnap } from '../utils';
import { HeaderButtons } from './Buttons';
import { SnapLogo } from './SnapLogo';
import { Toggle } from './Toggle';

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
`;

const Title = styled.p`
  font-size: ${(props) => props.theme.fontSizes.title};
  font-weight: bold;
  margin: 0;
  margin-left: 1.2rem;
  ${({ theme }) => theme.mediaQueries.small} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Link = styled.a`
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSizes.small};
  border-radius: ${(props) => props.theme.radii.button};
  // border: 1px solid ${(props) => props.theme.colors.background.inverse};
  background-color: ${(props) => props.theme.colors.background.inverse};
  color: ${(props) => props.theme.colors.text.inverse};
  text-decoration: none;
  padding: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  // &:hover {
  //   background-color: transparent;
  //   // border: 1px solid ${(props) => props.theme.colors.background.inverse};
  //   color: ${(props) => props.theme.colors.text.default};
  // }

  ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Header = ({
  handleToggleClick,
}: {
  handleToggleClick(): void;
}) => {
  const theme = useTheme();
  // const [state, dispatch] = useContext(MetaMaskContext);

  // const handleConnectClick = async () => {
  //   try {
  //     await connectSnap();
  //     const installedSnap = await getSnap();

  //     dispatch({
  //       type: MetamaskActions.SetInstalled,
  //       payload: installedSnap,
  //     });
  //   } catch (e) {
  //     console.error(e);
  //     dispatch({ type: MetamaskActions.SetError, payload: e });
  //   }
  // };
  return (
    <HeaderWrapper>
      <Link href="/">
        <LogoWrapper>
          <SnapLogo color={theme.colors.icon.default} size={36} />
          <Title>Riff Snap</Title>
        </LogoWrapper>
      </Link>

      <RightContainer>
        <Toggle
          onToggle={handleToggleClick}
          defaultChecked={getThemePreference()}
        />
        {/* <HeaderButtons state={state} onConnectClick={handleConnectClick} /> */}
      </RightContainer>
    </HeaderWrapper>
  );
};
